import { motion, Variants } from "framer-motion"

const cardVariants: Variants = {
  offscreen: {
    y: 75,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.8,
    },
  },
}

export const Container = ({ children, className, style }: any) => {
  return (
    <motion.div initial="offscreen" whileInView="onscreen" viewport={{ once: true, amount: 0.8 }}>
      <motion.div variants={cardVariants} className={className} style={style}>
        {children}
      </motion.div>
    </motion.div>
  )
}

import { LinksFunction } from "@remix-run/node";
import styles from "./style.css";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export function Scroller(props: {className: string;style?: any;children: any;timer?: string;itemStyle?: any;}) {
  let timer = props.timer;
  if (!timer) {
    timer = "30";
  }

  return (
    <div className={`scroller imgs ${props.className}`} style={{ "--t": `${timer}s`, ...props.style }}>
      <div className="gap-4">
        {props.children.map((child, index) =>
        <span style={props.itemStyle} key={index}>
            {child}
          </span>
        )}
      </div>
      <div className="gap-4">
        {props.children.map((child, index) =>
        <span style={props.itemStyle} key={`dup-${index}`}>
            {child}
          </span>
        )}
      </div>
    </div>);

}
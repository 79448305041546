import { json, redirect } from "@remix-run/node"
import type { LoaderFunctionArgs, MetaFunction, LinksFunction } from "@remix-run/node"
import { Link, useLoaderData, useRouteLoaderData } from "@remix-run/react"
import { Public } from "~/utils/remix"
import { Scroller, links as scrollerLinks } from "~/components/scroller"
import type { Variants } from "framer-motion"
import { Card } from "~/components/ui/card"
import { Button } from "~/components/ui/button"
import img1 from "~/assets/homepage/top-slide1.webp"
import img2 from "~/assets/homepage/top-slide2.webp"
import img3 from "~/assets/homepage/top-slide3.webp"
import img6 from "~/assets/homepage/top-slide6.webp"
import img7 from "~/assets/homepage/top-slide7.webp"
import img8 from "~/assets/homepage/top-slide8.webp"
import img9 from "~/assets/homepage/top-slide9.webp"
import img10 from "~/assets/homepage/top-slide10.webp"
import img11 from "~/assets/homepage/top-slide11.webp"
import img12 from "~/assets/homepage/top-slide12.webp"
import img13 from "~/assets/homepage/top-slide13.webp"
import img14 from "~/assets/homepage/top-slide14.webp"
import img15 from "~/assets/homepage/top-slide15.webp"
import img17 from "~/assets/homepage/top-slide17.webp"
import Footer from "~/components/footer"
import Nav from "~/components/nav"
import Typography from "~/components/typography"
import { commitUserSession, createMasqueradeSession } from "~/utils/session.server"
import { Slide, Slideshow, links as slideshowLinks } from "~/components/slideshow"
import original1 from "~/assets/homepage/s1.webp"
import original2 from "~/assets/homepage/s2.webp"
import original3 from "~/assets/homepage/s3.webp"
import original4 from "~/assets/homepage/s4.webp"
import original5 from "~/assets/homepage/s5.webp"
import original6 from "~/assets/homepage/s6.webp"
import original7 from "~/assets/homepage/s7.webp"
import original8 from "~/assets/homepage/s8.webp"
import original9 from "~/assets/homepage/s9.webp"
import showdown from "showdown"
import { Avatar, AvatarImage } from "~/components/ui/avatar"
import { getImage } from "~/utils/image.server"
import defaultAvatar from "~/assets/default-avatar.webp"
import { LRUCache } from "lru-cache"
import { FindUser } from "~/models/users.server"
import type { Users } from "@prisma/client"
import { useEffect, useRef, useState } from "react"
import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useTheme } from "remix-themes"
import arielImg from "~/assets/mlnp-team/ariel.webp"
import angieImg from "~/assets/mlnp-team/angie.webp"
import saniyyahImg from "~/assets/mlnp-team/saniyyah.webp"
import { cn } from "~/utils/shadecn"
import { Container } from "~/components/container"
import PromotionBanner from "~/components/promotion-banner"
import ogImage from "~/assets/og-image.jpg"

export const meta: MetaFunction = () => {
  return [
    { title: "MakeLoveNotPorn Home - Real World Sex Videos" },
    { property: "og:title", content: "MakeLoveNotPorn Home - Real World Sex Videos" },
    { property: "og:image", content: `https://makelovenotporn.tv${ogImage}` },

    {
      name: "description",
      content:
        "Ready to make love, not porn? Explore thousands of real world sex videos from real world loving couples, curated by humans to be ethical, sexy, and safe.",
    },
  ]
}

export const links: LinksFunction = () => [...scrollerLinks(), ...slideshowLinks()]

const commentIDs = [6906, 23814, 24561, 3948, 5318, 9578, 2430, 2154, 2186, 9607, 6767, 22099, 28595, 5658, 6987, 1508]

const cache = new LRUCache({ ttl: 1000 * 60 * 5 })

export const loader = Public(async ({ request, context }: LoaderFunctionArgs) => {
  const url = new URL(request.url)
  const masquerade = url.searchParams.get("masquerade")

  if (masquerade && context.user?.Role === 1) {
    let masqUser: Users | null = null

    if (parseInt(masquerade).toString() === masquerade) {
      masqUser = await FindUser(context, { UserID: masquerade })
    } else {
      masqUser = await FindUser(context, { UserName: masquerade })
    }
    if (!masqUser) {
      throw json({ message: "User not found" }, { status: 404 })
    }

    context.logger.info(`Admin ${context.user?.UserID} masquerading as ${masqUser.UserName}`)

    const session = await createMasqueradeSession(request, masqUser.UserID.toString())

    return redirect("/happy-place", {
      headers: {
        "Set-Cookie": await commitUserSession(session),
      },
    })
  }

  if (!cache.has("comments")) {
    const comments = await context.db.videoComment.findMany({
      where: { CommentID: { in: commentIDs } },
      include: {
        User: {
          select: {
            UserName: true,
            DisplayName: true,
            Avatar: true,
          },
        },
      },
    })

    cache.set(
      "comments",
      comments.map((comment) => ({
        ...comment,
        Avatar: getImage(comment.User?.Avatar, defaultAvatar),
      }))
    )
  }

  return json({
    env: process.env.MLNP_ENV,
    user: context.user,
    comments: cache.get("comments"),
  })
})

export default function Index() {
  const data = useLoaderData<typeof loader>()
  const { user, avatar, notifications, subCredits, bundleCredits, version, env } = useRouteLoaderData("root")
  const [theme, setTheme] = useTheme()
  const converter = new showdown.Converter()
  const [scrollTop, setScrollTop] = useState(0)
  const arrow = useRef<HTMLDivElement>(null)
  const [arrowScrolled, setArrowScrolled] = useState(false)

  const onScroll = () => {
    setScrollTop(window.scrollY)
  }

  useEffect(() => {
    if (window.scrollY > 0) return

    arrow.current!.className = cn(arrow.current!.className, "visible")

    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    if (arrowScrolled) return

    if (scrollTop > 50) {
      setArrowScrolled(true)
      arrow.current!.className = `${arrow.current!.className} opacity-0`
    }
  }, [scrollTop])

  const TOPSLIDES = [
    {
      link: "/videos/55905",
      key: "top-slide1.png",
      img: img1,
    },
    {
      link: "/videos/55949",
      key: "top-slide2.jpg",
      img: img2,
    },
    {
      link: "/videos/55361",
      key: "top-slide3.png",
      img: img3,
    },
    {
      link: "/videos/51406",
      key: "top-slide6.jpg",
      img: img6,
    },
    {
      link: "/videos/51415",
      key: "top-slide7.png",
      img: img7,
    },
    {
      link: "/videos/51452",
      key: "top-slide8.jpg",
      img: img8,
    },
    {
      link: "/videos/55390",
      key: "top-slide9.png",
      img: img9,
    },
    {
      link: "/videos/45511",
      key: "top-slide10.png",
      img: img10,
    },
    {
      link: "/videos/56073",
      key: "top-slide11.png",
      img: img11,
    },
    {
      link: "/videos/54673",
      key: "top-slide12.png",
      img: img12,
    },
    {
      link: "/videos/30365",
      key: "top-slide13.png",
      img: img13,
    },
    {
      link: "/videos/55319",
      key: "top-slide14.png",
      img: img14,
    },
    {
      link: "/videos/30964",
      key: "top-slide15.png",
      img: img15,
    },
    {
      link: "/videos/52041",
      key: "top-slide17.png",
      img: img17,
    },
  ]

  const vidVariants: Variants = {
    offscreen: {
      x: -1000,
    },
    onscreen: {
      x: 0,
      transition: {
        duration: 1,
      },
    },
  }
  const originalCarouselVariants: Variants = {
    offscreen: {
      x: 1000,
    },
    onscreen: {
      x: 0,
      transition: {
        delay: 0.5,
        duration: 1,
      },
    },
  }

  return (
    <>
      <Nav
        user={user}
        avatar={avatar}
        notificationCount={notifications}
        subCredits={subCredits}
        bundleCredits={bundleCredits}
        theme={theme || undefined}
        setTheme={setTheme}
      />
      <div
        ref={arrow}
        className="fixed w-screen hidden bottom-0 flex items-center justify-center transition duration-300 ease-linear text-white">
        <ChevronDownIcon className="w-24 h-24 animate-bounce" />
      </div>
      <div>
        <div
          className="w-full h-screen overflow-hidden flex justify-center"
          style={{ backgroundImage: "linear-gradient(135deg, rgb(255, 157, 112) 0%, rgb(158, 21, 14) 100%)" }}>
          <div className="flex w-full relative">
            <div className="absolute left-0 px-10 md:px-20 top-0 bottom-0 z-10 flex flex-col justify-center items-start text-white bg-gradient-to-r from-zinc-900 to-transparent">
              <h1 className="text-3xl font-black tracking-tight md:text-5xl drop-shadow-2xl">Make Love, Not Porn</h1>
              <h2 className="mt-2 text-sm font-light md:text-2xl drop-shadow-2xl">
                Pro-sex. Pro-porn. Pro-knowing the difference.
              </h2>
              <p className="mt-8 md:mt-12 text-md font-extralight lg:leading-[2.5rem] md:text-2xl drop-shadow-2xl">
                <span className="font-black text-lg md:text-3xl">Real World Sex</span>
                <br />
                in all its glorious,
                <br />
                silly, beautiful, messy,
                <br />
                reassuring <b className="font-bold">humanness</b>.
                <br />
                How to be great in bed
                <br />
                the real world way.
              </p>
              <p>
                <Link to={user ? "/videos" : "/register"}>
                  <Button className="mt-12 md:mt-16 drop-shadow-2xl py-4 px-8 font-bold text-xl md:text-3xl h-auto">
                    Watch Now
                  </Button>
                </Link>
              </p>
            </div>
            <div
              className="top-[64px] bottom-0 flex-1 flex flex-col gap-4 justify-center content-end"
              style={{
                marginLeft: "calc(-800px + 5vw)",
                transform: "translateZ(-100px) perspective(1000px) rotateY(-30deg)",
              }}>
              <Scroller className="flex-none absolute" timer="300">
                {TOPSLIDES.map((slide, index) => (
                  <div key={index} className="h-[20vh] aspect-video overflow-hidden shadow-lg rounded">
                    <img src={slide.img} className="w-full h-full scale-[1.02]" />
                  </div>
                ))}
              </Scroller>
              <Scroller className="flex-none absolute" style={{ transform: "translateX(-4%)" }} timer="300">
                {[...TOPSLIDES.slice(5, TOPSLIDES.length), ...TOPSLIDES.slice(0, 5)].map((slide, index) => (
                  <div key={index} className="h-[20vh] aspect-video overflow-hidden shadow-lg rounded">
                    <img src={slide.img} className="w-full h-full scale-[1.02]" />
                  </div>
                ))}
              </Scroller>
              <Scroller className="flex-none absolute" style={{ transform: "translateX(-8%)" }} timer="300">
                {[...TOPSLIDES.slice(10, TOPSLIDES.length), ...TOPSLIDES.slice(0, 10)].map((slide, index) => (
                  <div key={index} className="h-[20vh] aspect-video overflow-hidden shadow-lg rounded">
                    <img src={slide.img} className="w-full h-full scale-[1.02]" />
                  </div>
                ))}
              </Scroller>
            </div>
            <div className="absolute right-0 top-24 px-10 z-20 flex flex-col justify-start items-center text-white">
              <PromotionBanner />
            </div>
          </div>
        </div>

        <div className="w-screen grid grid-cols-1 justify-between items-center bg-zinc-800 overflow-hidden">
          <Container className="px-8">
            <h1 className="font-sans flex-none font-bold text-foreground text-xl sm:text-3xl lg:text-4xl text-center pt-16 sm:pt-20 md:pt-28 pb-16 sm:pb-20 md:pb-28 tracking-tight text-white">
              Porn doesn't make you good in bed - real world sex does.
            </h1>
          </Container>
          <div className="relative pb-28 md:pb-32 md:px-16 grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
            <div>
              <Container>
                <div className="bg-zinc-700 rounded-lg overflow-hidden shadow-lg border border-gray-700 md:transform md:transition-transform md:duration-300 md:hover:scale-105">
                  <div className="relative pb-[56.25%] h-0 rounded-t-lg overflow-hidden bg-black">
                    <iframe
                      className="absolute inset-0 w-full h-full"
                      title="The Hidden Benefits of Watching Real Sex ft. Cindy Gallop"
                      src="https://www.youtube.com/embed/aG_ZaR_bW4I?si=UtuzzFQBpRr4MNRb&rel=0&modestbranding=1&controls=1&color=white"
                      allowFullScreen
                      loading="lazy"></iframe>
                  </div>
                </div>
              </Container>
            </div>
            <div>
              <Container>
                <div className="bg-zinc-700 rounded-lg overflow-hidden shadow-lg border border-gray-700 md:transform md:transition-transform md:duration-300 md:hover:scale-105">
                  <div className="relative pb-[56.25%] h-0 rounded-t-lg overflow-hidden bg-black">
                    <iframe
                      className="absolute inset-0 w-full h-full"
                      title="How To Have A Great Sex Life: The Secrets We Are Never Told! With Cindy Gallop."
                      src="https://www.youtube.com/embed/hWYPgMXhzrY?si=hcGJ_b_952yZmGqu&rel=0&modestbranding=1&controls=1&color=white"
                      allowFullScreen
                      loading="lazy"></iframe>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>

        <div className="w-screen flex flex-col bg-primary-dark pb-16 sm:pb-20 md:pb-28">
          <Container className="px-8 lg:px-60">
            <h1 className="font-sans flex-none font-black text-white text-xl sm:text-3xl text-center pt-16 sm:pt-20 md:pt-28 pb-2">
              MakeLoveNotPorn is where real love, intimacy and human connection is celebrated, transformative, and
              attainable.
            </h1>
          </Container>
          <Container>
            <p className="text-white text-center text-md sm:text-xl font-extralight tracking-wider pb-2 sm:pb-4 md:pb-8">
              See what our members say:
            </p>
            <div className="flex flex-col overflow-x-hidden box-border justify-center">
              <div className="testimonials flex-1 flex overflow-x-hidden py-2 sm:py-4">
                <div className="flex justify-start items-end gap-4 sm:gap-8 px-2 sm:px-4 set" style={{ "--t": "500s" }}>
                  {data.comments.map((comment, index) => (
                    <Card
                      key={index}
                      radius="md"
                      className="border-none w-40 sm:w-72 flex-none flex flex-col justify-between py-0 bg-background/80 hover:scale-110 transition-transform">
                      <div className="px-2 sm:px-4">
                        <div className="flex justify-between items-center mt-2 sm:mt-4">
                          <div className="flex justify-start items-center">
                            <Avatar className="h-3 w-3 sm:h-4 sm:w-4 mr-2">
                              <AvatarImage src={comment.Avatar} className="object-cover" alt="avatar" />
                            </Avatar>
                            <b className="text-[0.5rem] sm:text-xs">
                              {comment.User.Status === 1 && comment.User.Private !== 1 && comment.User.Deleted === 0 ?
                                <Link className="hover:underline" to={`/makelovenotpornstars/${comment.UserID}`}>
                                  {comment.User.DisplayName}
                                </Link>
                              : <>{comment.User.DisplayName}</>}
                            </b>
                          </div>
                          <Link
                            to={`/videos/${comment.VideoID}`}
                            className="text-[0.5rem] sm:text-xs text-primary hover:underline mr-2 sm:mr-0">
                            Watch Video
                          </Link>
                        </div>
                        <Typography className="text-[0.5rem] sm:text-xs">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: converter.makeHtml(
                                unescapeHTML(decodeURI(comment.Content)).replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""
                              ),
                            }}
                          />
                        </Typography>
                      </div>
                    </Card>
                  ))}
                </div>
                <div className="flex justify-start items-end gap-4 sm:gap-8 px-2 sm:px-4 set" style={{ "--t": "500s" }}>
                  {data.comments.map((comment, index) => (
                    <Card
                      key={index}
                      radius="md"
                      className="border-none w-40 sm:w-72 flex-none flex flex-col justify-between py-0 bg-background/80 hover:scale-110 transition-transform">
                      <div className="px-2 sm:px-4">
                        <div className="flex justify-between items-center mt-2 sm:mt-4">
                          <div className="flex justify-start items-center">
                            <Avatar className="h-3 w-3 sm:h-4 sm:w-4 mr-2">
                              <AvatarImage src={comment.Avatar} className="object-cover" alt="avatar" />
                            </Avatar>
                            <b className="text-[0.5rem] sm:text-xs">
                              {comment.User.Status === 1 && comment.User.Private !== 1 && comment.User.Deleted === 0 ?
                                <Link className="hover:underline" to={`/makelovenotpornstars/${comment.UserID}`}>
                                  {comment.User.DisplayName}
                                </Link>
                              : <>{comment.User.DisplayName}</>}
                            </b>
                          </div>
                          <Link
                            to={`/videos/${comment.VideoID}`}
                            className="text-[0.5rem] sm:text-xs text-primary hover:underline mr-2 sm:mr-0">
                            Watch Video
                          </Link>
                        </div>
                        <Typography className="text-[0.5rem] sm:text-xs">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: converter.makeHtml(
                                unescapeHTML(decodeURI(comment.Content)).replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""
                              ),
                            }}
                          />
                        </Typography>
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
              <div className="testimonials reverse flex-1 flex overflow-x-hidden py-2 sm:py-4">
                <div
                  className="flex justify-start items-start gap-4 sm:gap-8 px-2 sm:px-4 set"
                  style={{ "--t": "300s" }}>
                  {data.comments
                    .slice()
                    .reverse()
                    .map((comment, index) => (
                      <Card
                        key={index}
                        radius="md"
                        className="border-none w-40 sm:w-72 flex-none flex flex-col justify-between py-0 bg-background/80 hover:scale-110 transition-transform">
                        <div className="px-2 sm:px-4">
                          <div className="flex justify-between items-center mt-2 sm:mt-4">
                            <div className="flex justify-start items-center">
                              <Avatar className="h-3 w-3 sm:h-4 sm:w-4 mr-2">
                                <AvatarImage src={comment.Avatar} className="object-cover" alt="avatar" />
                              </Avatar>
                              <b className="text-[0.5rem] sm:text-xs">
                                {comment.User.Status === 1 && comment.User.Private !== 1 && comment.User.Deleted === 0 ?
                                  <Link className="hover:underline" to={`/makelovenotpornstars/${comment.UserID}`}>
                                    {comment.User.DisplayName}
                                  </Link>
                                : <>{comment.User.DisplayName}</>}
                              </b>
                            </div>
                            <Link
                              to={`/videos/${comment.VideoID}`}
                              className="text-[0.5rem] sm:text-xs text-primary hover:underline mr-2 sm:mr-0">
                              Watch Video
                            </Link>
                          </div>
                          <Typography className="text-[0.5rem] sm:text-xs">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(
                                  unescapeHTML(decodeURI(comment.Content)).replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""
                                ),
                              }}
                            />
                          </Typography>
                        </div>
                      </Card>
                    ))}
                </div>
                <div
                  className="flex justify-start items-start gap-4 sm:gap-8 px-2 sm:px-4 set"
                  style={{ "--t": "300s" }}>
                  {data.comments
                    .slice()
                    .reverse()
                    .map((comment, index) => (
                      <Card
                        key={index}
                        radius="md"
                        className="border-none w-40 sm:w-72 flex-none flex flex-col justify-between py-0 bg-background/80 hover:scale-110 transition-transform">
                        <div className="px-2 sm:px-4">
                          <div className="flex justify-between items-center mt-2 sm:mt-4">
                            <div className="flex justify-start items-center">
                              <Avatar className="h-3 w-3 sm:h-4 sm:w-4 mr-2">
                                <AvatarImage src={comment.Avatar} className="object-cover" alt="avatar" />
                              </Avatar>
                              <b className="text-[0.5rem] sm:text-xs">
                                {comment.User.Status === 1 && comment.User.Private !== 1 && comment.User.Deleted === 0 ?
                                  <Link className="hover:underline" to={`/makelovenotpornstars/${comment.UserID}`}>
                                    {comment.User.DisplayName}
                                  </Link>
                                : <>{comment.User.DisplayName}</>}
                              </b>
                            </div>
                            <Link
                              to={`/videos/${comment.VideoID}`}
                              className="text-[0.5rem] sm:text-xs text-primary hover:underline mr-2 sm:mr-0">
                              Watch Video
                            </Link>
                          </div>
                          <Typography className="text-[0.5rem] sm:text-xs">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(
                                  unescapeHTML(decodeURI(comment.Content)).replace(/(?:\r\n|\r|\n)/g, "  \r\n") || ""
                                ),
                              }}
                            />
                          </Typography>
                        </div>
                      </Card>
                    ))}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="w-screen flex flex-col bg-zinc-200 text-zinc-700 pb-16 sm:pb-20 md:pb-28">
          <div className="w-full lg:max-w-7xl mx-auto">
            <Container className="px-8">
              <h1 className="font-sans text-zinc-700 flex-none font-bold text-foreground text-2xl sm:text-3xl lg:text-4xl text-center pt-16 sm:pt-20 md:pt-28 pb-2 md:pb-4 lg:pb-8 tracking-tight">
                We’re the safest place on the internet
              </h1>
            </Container>
            <div className="relative px-8 flex flex-col gap-8 sm:gap-8 md:gap-20 md:px-24">
              <Typography className="text-pretty h-full text-sm sm:text-md md:text-xl font-light m-auto mt-1 sm:mt-4 sm:mx-16">
                <div className="flex flex-col gap-4 md:gap-2">
                  <h3 className="text-center text-zinc-700">There is no self-publishing on MakeLoveNotPorn.</h3>
                  <p className="text-zinc-700 text-center leading-relaxed">
                    Our curators watch every video submitted from beginning to end, before we approve (or reject) and
                    publish it. Our curators also review every profile post and video comment before we approve (or
                    reject) and publish them. No one else does this.
                  </p>
                </div>
                <div className="flex flex-col gap-4 md:gap-2">
                  <h3 className="text-zinc-900 text-center">MakeLoveNotPorn revenue-shares.</h3>
                  <p className="text-zinc-700 text-center leading-relaxed">
                    Each time a video is rented, 50% goes directly back to the MakeLoveNotPornstar.
                  </p>
                </div>
              </Typography>
              <Typography className="text-sm sm:text-md md:text-xl px-8 py-10 lg:p-20 bg-zinc-300 rounded-lg border border-[#d1d1dd] h-full shadow flex flex-col gap-2 sm:gap-4">
                <h3 className="text-zinc-900 text-center">Meet Our Curation Team</h3>
                <div className="flex items-start justify-center gap-4 md:gap-8 lg:gap-12 mt-2 md:mt-8 text-center">
                  <div className="flex flex-col items-center">
                    <Link to="/whos-behind-it">
                      <img
                        className="not-prose aspect-square object-cover rounded-full shadow-lg max-h-40"
                        src={angieImg}
                        alt="Angie Cosmo"
                      />
                    </Link>
                    <p className="text-zinc-700 mt-2 text-xs sm:text-md md:text-xl block text-center">
                      <b className="font-black">Angie Cosmo</b>
                      <br />
                      Curator
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <Link to="/whos-behind-it">
                      <img
                        className="not-prose aspect-square object-cover rounded-full shadow-lg max-h-40"
                        src={arielImg}
                        alt="Ariel Martinez"
                      />
                    </Link>
                    <p className="text-zinc-700 mt-2 text-xs sm:text-md md:text-xl block text-center">
                      <b className="font-black">Ariel Martinez</b>
                      <br />
                      Head of Curation
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <Link to="/whos-behind-it">
                      <img
                        className="not-prose aspect-square object-cover rounded-full shadow-lg max-h-40"
                        src={saniyyahImg}
                        alt="Saniyyah Lateef"
                      />
                    </Link>
                    <p className="text-zinc-700 mt-2 text-xs sm:text-md md:text-xl block text-center">
                      <b className="font-black">Saniyyah Lateef</b>
                      <br />
                      Curator
                    </p>
                  </div>
                </div>
                <p className="text-zinc-700 leading-relaxed max-w-3xl mx-auto">
                  Our Curators are here to help you. Are you looking for something specific in real world sex? Would you
                  like education, inspiration, solutions for any sexual issues you're encountering yourself? Want to try
                  something and would love to see how it works in the real world first?
                </p>
                <p className="text-zinc-700 text-center leading-relaxed max-w-3xl mx-auto">
                  Email <Link to="mailto:curators@makelovenotporn.tv">curators@makelovenotporn.tv</Link> for
                  recommendations and guidance.
                </p>
              </Typography>
            </div>
          </div>
        </div>

        <div className="w-screen grid grid-cols-1 justify-between items-center bg-zinc-800 overflow-hidden">
          <Container>
            <h1 className="w-full py-16 sm:py-28 font-extralight tracking-wider text-white text-balance text-lg sm:text-2xl md:text-3xl text-center">
              MakeLoveNotPorn was born in 2009 out of founder
              <br />
              Cindy Gallop's direct personal experience dating younger men.
            </h1>
          </Container>
          <div className="relative pb-16 sm:pb-28 md:px-16 grid grid-cols-1 gap-12 md:gap-24">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-24">
              <Container>
                <div className="bg-zinc-800 rounded-lg overflow-hidden shadow-lg border border-gray-700 transform transition-transform duration-300 hover:scale-105">
                  <div className="relative pb-[56.25%] h-0 rounded-t-lg overflow-hidden bg-black">
                    <iframe
                      title="Cindy Gallop's TED Talk"
                      className="absolute inset-0 w-full h-full"
                      src="https://embed.ted.com/talks/cindy_gallop_make_love_not_porn"
                      allowFullScreen
                      loading="lazy"></iframe>
                  </div>
                  <Typography>
                    <div className="p-4 bg-zinc-700">
                      <h3 className="font-extralight text-center tracking-wider mt-0 sm:mt-4 text-white text-lg sm:text-xl lg:text-3xl">
                        Watch Cindy's TED Talk
                      </h3>
                    </div>
                  </Typography>
                </div>
              </Container>
              <Container>
                <Slideshow className="mx-auto mt-4" style={{ paddingTop: "1rem" }}>
                  <Slide src={original1} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original2} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original3} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original4} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original5} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original6} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original7} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original8} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                  <Slide src={original9} className="rounded-2xl overflow-hidden aspect-[2/1] w-full" />
                </Slideshow>
                <Typography>
                  <h3 className="font-extralight text-center tracking-wider mt-0 sm:mt-4 text-white text-lg sm:text-xl lg:text-3xl">
                    The Original MakeLoveNotPorn Website
                  </h3>
                </Typography>
              </Container>
            </div>
            <Container>
              <Typography className="md:px-[20%] my-4 text-balance text-center flex flex-col items-center justify-center text-white px-6">
                <p>
                  Today MakeLoveNotPorn is the world's first user-generated, human-curated social sex platform - the
                  YouTube of real world sex: sharing that one area of all our lives no other social platform will allow.
                </p>
                <p>
                  Our real world sex videos aren't just something you watch and enjoy - they're inspirational self help
                  for your sex life (just read our members' comments on each video to see how).
                </p>
                <p>
                  <Link to="/book">
                    Read Cindy's TED book about how it all began - only <b>$4.99</b>
                  </Link>
                </p>
              </Typography>
            </Container>
          </div>
        </div>
        <div className="w-screen flex flex-col px-2 text-xl sm:text-3xl bg-zinc-800 pb-16 sm:pb-20 md:pb-28 px-6">
          <Container>
            <h1 className="font-sans flex-none font-black text-white text-[1.7rem] sm:text-4xl md:text-5xl text-center pt-16 sm:pt-20 md:pt-28 pb-6">
              Join The Social Sex Revolution!
            </h1>
          </Container>
          <Typography className="prose-sm subpixel-antialiased text-zinc-200 text-center">
            <Container>
              <h1 className="text-xl sm:text-2xl md:text-4xl font-extralight tracking-tight text-pretty text-zinc-200 mb-6">
                Ready to see what everybody else, all around the world, is <i className="font-black">really</i> doing in
                bed?
              </h1>
            </Container>
            <Container>
              <div className="p-4 font-thin tracking-wider text-pretty">
                <p className="text-md sm:text-2xl">
                  <b>1.</b> &nbsp;&nbsp;Sign up for free
                </p>
                <p className="text-md sm:text-2xl">
                  <b>2.</b> &nbsp;&nbsp;Browse thousands of videos, with new uploads every day
                </p>
                <p className="text-md sm:text-2xl">
                  <b>3.</b> &nbsp;&nbsp;Subscribe or buy credits to rent and be inspired by real world sex
                </p>
                <p className="text-md sm:text-2xl">
                  <b>4.</b> &nbsp;&nbsp;Support MakeLoveNotPornstars who earn half of each rental
                </p>
              </div>
            </Container>
          </Typography>
          <Container className="text-center pt-4 sm:pt-8">
            <Link to="/register">
              <Button className="h-8 sm:h-12 px-10 py-8 text-2xl font-light tracking-wider">Create Your Account</Button>
            </Link>
          </Container>
        </div>
        <Footer className="w-screen" version={version} env={env} />
      </div>
    </>
  )
}

var htmlEntities: any = {
  nbsp: " ",
  cent: "¢",
  pound: "£",
  yen: "¥",
  euro: "€",
  copy: "©",
  reg: "®",
  lt: "<",
  gt: ">",
  quot: '"',
  amp: "&",
  apos: "'",
}

function unescapeHTML(str: string) {
  return str.replace(/\&([^;]+);/g, function (entity, entityCode) {
    var match

    if (entityCode in htmlEntities) {
      return htmlEntities[entityCode]
    } else if ((match = entityCode.match(/^#x([\da-fA-F]+)$/))) {
      return String.fromCharCode(parseInt(match[1], 16))
    } else if ((match = entityCode.match(/^#(\d+)$/))) {
      return String.fromCharCode(~~match[1])
    } else {
      return entity
    }
  })
}
